<template>
  <div id="home">
    <div class="MmsUiPageTitle">
      Welcome to the service system for call center
    </div>
    <!-- <div class="call-center-breadcrumb">
      <router-link to="/">路由1</router-link>
      <span> / </span>
      <router-link to="/">路由2</router-link>
      <span> / </span>
      <span class="last">当前页面</span>
    </div>
    <div class="cc-list-tap-container">
      <div class="cc-list-taps">
        <div
          class="cc-list-tap-item"
          :class="{ active: activeTab === 1 }"
          @click="activeTab = 1"
        >
          <div class="tab-item-text">线上商品</div>
        </div>
        <div
          class="cc-list-tap-item"
          :class="{ active: activeTab === 2 }"
          @click="activeTab = 2"
        >
          <div class="tab-item-text">上传记录</div>
        </div>
      </div>
    </div>
    <div className="cc-list-content">
      <div className="cc-search-wrap">
        <a-form name="basic" layout="vertical" autoComplete="off">
          <a-form-item label="商品ID" name="id">
            <a-input placeholder="请输入商品id" />
          </a-form-item>
          <a-form-item label="商品分类" name="category">
            <a-cascader placeholder="请选择" />
          </a-form-item>
          <a-form-item label="商品名称" name="name">
            <a-input placeholder="请输入商品名称" />
          </a-form-item>
          <a-form-item label="商品编码" name="code">
            <a-input placeholder="请输入商品编码" />
          </a-form-item>
          <a-form-item label="累计销量">
            <a-form-item label="" name="minsales" class="double-child">
              <a-input placeholder="最低值" style="width: 100px" />
            </a-form-item>
            <span class="hor-line">-</span>
            <a-form-item label="" name="maxsales" class="double-child">
              <a-input placeholder="最高值" style="width: 100px" />
            </a-form-item>
          </a-form-item>
          <a-form-item label="当前价">
            <a-form-item label="" name="minprice" class="double-child">
              <a-input placeholder="最低价" style="width: 100px" />
            </a-form-item>
            <span class="hor-line">-</span>
            <a-form-item label="" name="maxprice" class="double-child">
              <a-input placeholder="最高价" style="width: 100px" />
            </a-form-item>
          </a-form-item>
          <a-form-item label="是否预售" name="presale">
            <a-select placeholder="请选择" allowClear>
              <a-select-option value="-1">不限</a-select-option>
              <a-select-option value="1">非预售</a-select-option>
              <a-select-option value="2">预售</a-select-option>
              <a-select-option value="3">sku预售</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="是否新品" name="newproducts">
            <a-select placeholder="请选择" allowClear>
              <a-select-option value="-1">不限</a-select-option>
              <a-select-option value="1">新品</a-select-option>
              <a-select-option value="2">非新品</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item style="margin: 0 36px 16px">
            <a-button type="primary" htmlType="submit">查 询</a-button>
            <a-button style="marginleft: 12px" @click="add">重 置</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div> -->
  </div>
</template>

<script>
import { setTheLocalStore } from '@/utils/setTheLocalStore'
export default {
  name: '',
  data() {
    return {
      activeTab: 1,
      name: '',
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="less" scoped>
// @import '../../assets/cc-list-tap-container.less';
// @import '../../assets/cc-search-wrap.less';
.MmsUiPageTitle {
  height: 60px;
  background-color: #fff;
  padding: 20px;
}
</style>
